.App {
  text-align: center;
}

.container {
  max-width: 80rem;
  padding-bottom: 7.5rem;
  padding-top: 7.5rem;
}

#home {
  position: relative;
  height: 100vh;
  max-height: 100vh;
  padding-top: 10rem;
  min-width: 100vw;
}
